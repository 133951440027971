import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { parseISO, isBefore, addDays, isAfter, addMinutes } from "date-fns";
import CartAbandonmentActions from "actions/cart-abandonment-actions";
import { displayModal } from "actions/shared-actions";
import { checkout, pageTypes } from "rtr-constants";
import ReferralHelper from "helpers/referral-helper";
import { getUserId } from "components/source/hoc/with-user-data";
import ClosableModal from "components/source/shared/closable-modal";
import ProgressStars from "images/acquisition/progress-stars.svg";
import { renderImportedImage } from "helpers/storefront-next-transition-helpers";
import { inferAction, logAction } from "action-logger";
import { idPropType } from "components/propTypes";

export class CartAbandonmentModal extends React.Component {
  static MODAL_TRIGGER_MINUTES_ELAPSED = 10;
  static EXPIRATION_DAYS_ELAPSED = 2;
  static MODAL_NAME = "SUBSCRIPTION_CART_ABANDONMENT_MODAL";
  static ANALYTICS_OBJECT_TYPE = "abandon_cart_modal";

  static propTypes = {
    cartAbandonmentStateTimestamp: PropTypes.string,
    clearCartAbandonmentState: PropTypes.func.isRequired,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    getCartAbandonmentStateFromStorage: PropTypes.func.isRequired,
    hasSubscriptionInBag: PropTypes.bool,
    onCloseModal: PropTypes.func.isRequired,
    onDisplayModal: PropTypes.func.isRequired,
    pageType: PropTypes.string,
    userId: idPropType,
  };

  componentDidMount() {
    this.props.getCartAbandonmentStateFromStorage();
  }

  componentDidUpdate(prevProps) {
    const {
      cartAbandonmentStateTimestamp,
      clearCartAbandonmentState,
      hasSubscriptionInBag,
      onDisplayModal,
      pageType,
      userId,
    } = this.props;

    const abandonmentStateIsNewlyPopulated = cartAbandonmentStateTimestamp !== prevProps.cartAbandonmentStateTimestamp;
    const bagIsNewlyPopulated = hasSubscriptionInBag !== prevProps.hasSubscriptionInBag;

    if (
      hasSubscriptionInBag &&
      cartAbandonmentStateTimestamp &&
      (bagIsNewlyPopulated || abandonmentStateIsNewlyPopulated)
    ) {
      const parsedTimestamp = parseISO(cartAbandonmentStateTimestamp || "");
      if (isBefore(addDays(parsedTimestamp, CartAbandonmentModal.EXPIRATION_DAYS_ELAPSED), new Date())) {
        // NW [EXPLANATION] 11/3/21: expiration has elapsed - user is no longer considered a cart-abandoner
        clearCartAbandonmentState();
      } else if (isAfter(new Date(), addMinutes(parsedTimestamp, CartAbandonmentModal.MODAL_TRIGGER_MINUTES_ELAPSED))) {
        if (this.userIsEligibleForModal()) {
          onDisplayModal(this.constructor.MODAL_NAME);
        }
        clearCartAbandonmentState();
        logAction({
          object_type: this.constructor.ANALYTICS_OBJECT_TYPE,
          action: "trigger",
          user_id: userId,
          url: window?.location?.href,
          page_type: pageType,
        });
      }
    }
  }

  userIsEligibleForModal() {
    return !ReferralHelper.getReferralCode();
  }

  onModalClose = () => {
    const { userId } = this.props;

    this.props.onCloseModal();
    logAction({
      object_type: this.constructor.ANALYTICS_OBJECT_TYPE,
      action: "close_modal",
      user_id: userId,
    });
  };

  onClickCta = e => {
    const { userId } = this.props;

    if (this.props.pageType === pageTypes.NEW_CHECKOUT) {
      // NW [EXPLANATION] 11/22/21: prevent a page refresh when user is already on checkout page
      e?.stopPropagation();
      e?.preventDefault();
      this.onModalClose();
    }

    inferAction({
      object_type: this.constructor.ANALYTICS_OBJECT_TYPE,
      action: "click_cta",
      user_id: userId,
    });
  };

  renderIcon() {
    return renderImportedImage(ProgressStars, { title: "cart abandonment icon" });
  }

  renderDek() {
    return (
      <>
        <p>
          Wear designer clothes without
          <br />
          paying designer prices.
        </p>
        <p>
          Join now to immediately
          <br />
          access styles from 750+ designers.
        </p>
      </>
    );
  }

  render() {
    const { displayedModal } = this.props;

    return (
      <ClosableModal
        isOpen={displayedModal === this.constructor.MODAL_NAME}
        onRequestClose={this.onModalClose}
        optionalClass="modal-close-circle no-padding full-height-on-mobile"
        optionalWrapperClass="cart-abandonment-modal">
        <>
          {this.renderIcon()}
          <h3>You&apos;re so close!</h3>
          {this.renderDek()}
          <a className="btn primary" onClick={this.onClickCta} href="/order" aria-label="finish signup button">
            Finish Signing Up
          </a>
        </>
      </ClosableModal>
    );
  }
}

const mapStateToProps = state => {
  const orderItemIsSubscription = item => item?.subType === checkout.orderItemTypes.UPDATE_SUBSCRIPTION;
  const orderGroupHasSubscriptionItem = group => group?.items?.some(orderItemIsSubscription);

  return {
    cartAbandonmentStateTimestamp: state.cartAbandonmentStateTimestamp,
    displayedModal: state.displayedModal,
    hasSubscriptionInBag: state.activeOrders?.some(order => order?.groups?.some(orderGroupHasSubscriptionItem)),
    pageType: state.pageType,
    userId: getUserId(state.userData),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearCartAbandonmentState: () => dispatch(CartAbandonmentActions.clearCartAbandonmentState()),
    onCloseModal: () => dispatch(displayModal(false)),
    onDisplayModal: () => dispatch(displayModal(CartAbandonmentModal.MODAL_NAME)),
    getCartAbandonmentStateFromStorage: () => dispatch(CartAbandonmentActions.getCartAbandonmentStateFromStorage()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CartAbandonmentModal);
