import { LocalStorage } from "../site/localStorage";
import ActionTypes from "./action-types";
import { createAction } from "redux-actions";

const CART_ABANDONMENT_NAMESPACE = "subscriptionCartAbandonment";
const SUBSCRIPTION_ADDED_TO_BAG_KEY = "subscriptionAddedToBag";
const reduxSetCartAbandonmentState = createAction(ActionTypes.CART_ABANDONMENT_STATE_SET);

const actions = {
  initializeCartAbandonmentState(timestamp) {
    return function (dispatch) {
      const localStorageClient = new LocalStorage(CART_ABANDONMENT_NAMESPACE);
      localStorageClient.set(SUBSCRIPTION_ADDED_TO_BAG_KEY, timestamp);

      dispatch(reduxSetCartAbandonmentState(timestamp));
    };
  },

  getCartAbandonmentStateFromStorage() {
    return function (dispatch) {
      const localStorageClient = new LocalStorage(CART_ABANDONMENT_NAMESPACE);
      const dateAdded = localStorageClient.get(SUBSCRIPTION_ADDED_TO_BAG_KEY);

      if (dateAdded) {
        dispatch(reduxSetCartAbandonmentState(dateAdded));
      }
    };
  },

  clearCartAbandonmentState() {
    return function (dispatch) {
      const localStorageClient = new LocalStorage(CART_ABANDONMENT_NAMESPACE);
      const dateAdded = localStorageClient.remove(SUBSCRIPTION_ADDED_TO_BAG_KEY);

      if (dateAdded) {
        dispatch(reduxSetCartAbandonmentState(null));
      }
    };
  },
};

export default actions;

export const {
  initializeCartAbandonmentState,
  getCartAbandonmentStateFromStorage,
  clearCartAbandonmentState,
} = actions;
